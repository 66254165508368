@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/fonts';

.button {
    @include light-font(px2rem($inputFontSize), px2rem($inputFontSize));

    padding: px2rem(12) px2rem(20);
    border: 1px solid $darkYellow;
    background: $buttonBackground;
    cursor: pointer;

    &:focus {
        border-color: $focusBorderColor;
    }

    &:hover {
        background-color: $buttonHoverColor;
        border-color: $buttonHoverColor;
    }

    &[disabled] {
        background: $buttonDisabledBackground;
        border-color: $buttonDisabledBorderColor;
        color: $buttonDisabledColor;
        cursor: auto;
    }

    &--full-width {
        width: 100%;
    }

    &--secondary {
        background-color: $buttonSecondaryBackground;
        border: 1px solid $buttonSecondaryBackground;
    }

    &--small {
        padding: px2rem(3) px2rem(6);
    }
}
