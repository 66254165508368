@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/responsive-layout';

._headerContainer {
    display: flex;
    align-items: center;
}
._searchField__container {
    margin-bottom: px2rem(8);
    margin-left: auto;
}
._searchField {
    border: none;
    border-bottom: 1px solid $labelColor;
    padding-bottom: 0;

    &::placeholder {
        color: $lightGray;
    }
}

._editIcon {
    width: px2rem(16);
    height: px2rem(16);
}
