@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/responsive-layout';
@import '../../../styles/variables';

._paginationContainer {
    margin-top: px2rem($defaultGap);
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-wrap: wrap;
    justify-items: center;
    justify-content: center;
    @include for-size(tablet-portrait-up) {
        justify-content: space-between;
    }

    & button {
        margin: px2rem($defaultGap) px2rem(4);
        &:last-of-type {
            margin-right: 0;
        }
    }
    & span {
        padding-top: px2rem(4);
    }
}

._pageJump {
    margin: 0 px2rem(10) 0 px2rem(4);
}
._pageJump__input {
    width: px2rem(50);
    border: 1px solid $borderColor;
    padding: px2rem(5) px2rem(4) px2rem(1);
}
._pageCounter {
    margin: 0 px2rem(8);
}

._pageSizeContainer {
    display: flex;
}

._rowAmount {
    border: 1px solid $borderColor;
    margin-right: auto;
    margin-left: px2rem(4);
    padding: px2rem(4) px2rem(4) px2rem(2) px2rem(2);
}
