@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/fonts';
@import '../../../styles/responsive-layout';
@import '../../../styles/variables';

ul {
    padding-inline-start: 0px;
}

._documents button {
    margin-top: 0.4rem;
    margin-right: 0rem;
    @include for-size(tablet-portrait-up) {
        margin-right: 2rem;
    }
}

._background {
    background: #fffef5;
    border: 1px solid #e0b300;
    border-top: 0;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    @include for-size(tablet-portrait-up) {
        background: #fffef5;
        border: 1px solid #e0b300;
        border-top: 0;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

._header {
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

._date {
    padding-right: 1rem;
}

._time {
    padding-right: 1rem;
}

._reference {
    padding-left: 1rem;
}

._message {
    padding-bottom: 1rem;
}

._documents {
    padding-bottom: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    @include for-size(tablet-portrait-up) {
        padding-bottom: 1rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}
