@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/print';

._notificationBar {
    @include hide-in-print();
    padding: px2rem(12) px2rem(16);
    margin-bottom: px2rem(16);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s ease-in-out;
    // To use scrollIntoView on this element and prevent it from being hidden behind the header
    scroll-margin-top: 80px;
    scroll-snap-margin-top: 80px;

    &--success {
        background-color: $confirmedColor;
    }

    &--error {
        background-color: $errorBackgroundColor;
    }
}
