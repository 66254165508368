@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/fonts';
@import '../../styles/responsive-layout';
@import '../../styles/variables';

._subInfo {
    @include light-font(px2rem(14), px2rem(14));
    color: $labelColor;
}

._checkbox {
    margin-bottom: px2rem($defaultGap);
    margin-top: px2rem($defaultGap);
}
