@import '../../../../styles/colors';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../styles/responsive-layout';

._modifikationsContainer,
._amountsContainer,
._siloWerk,
._berechnungsartKontrakt {
    display: flex;
    & > div {
        flex: 1;
        margin-right: px2rem($defaultGap);
        &:last-of-type {
            margin-right: 0;
        }
    }
}

._amountsContainer {
    align-items: center;
}

._siloWerk {
    align-items: flex-start;
}

._modifikationsContainer {
    padding: px2rem($defaultGap) 0;
    align-items: center;
}

._artikelContainer {
    border-bottom: 1px solid $borderColor;
    margin: px2rem($defaultGap) 0;
    padding-bottom: px2rem(6);
    &:last-of-type {
        border-bottom: none;
        margin-bottom: px2rem(32);
    }
}

._artikelHeadline {
    @include stornoModifier;
}

._headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
