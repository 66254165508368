@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/responsive-layout';

._container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 px2rem(calc(#{-$defaultGap} / 2));
}

._notificationLink {
    color: $black;
}

.linkButton {
    @include light-font(px2rem(16), px2rem(16));

    padding: px2rem(12) px2rem(20);
    border: 1px solid $buttonBackground;
    background: $buttonBackground;
    cursor: pointer;
    text-decoration: none;
    color: $fontColor;
    text-align: center;

    width: calc(50% - #{px2rem($defaultGap)});
    margin: 0 px2rem(calc($defaultGap / 2)) px2rem($defaultGap) px2rem(calc($defaultGap / 2));

    @include for-size(desktop-up) {
        width: calc(25% - #{px2rem($defaultGap)});
    }

    &:focus {
        border-color: $focusBorderColor;
    }

    &:hover {
        background-color: $buttonHoverColor;
        border-color: $buttonHoverColor;
    }

    &[disabled] {
        background: lighten($buttonBackground, 40%);
        border-color: lighten($buttonBackground, 40%);
    }
}
