@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/fonts';

._input {
    @include light-font(px2rem($inputFontSize), px2rem($inputFontSize));
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid $borderColor;
    border-radius: 0;
    padding: px2rem(12) px2rem(8) px2rem(8);
    background: $inputBackgroundColor;
    width: 100%;
    height: px2rem(42);

    &:focus {
        border-color: $focusBorderColor;
        background: $focusBackgroundColor;
        outline: none;
    }

    &:hover {
        background: $lightYellow;
    }

    &--error {
        border: 1px solid $errorColor;
    }
}
