@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/fonts';

._label {
    display: block;
    @include light-font(px2rem(17), px2rem(17));
    margin-bottom: px2rem(2);
    color: $labelColor;

    &--error {
        color: $red;
    }

    &--no-wrap {
        white-space: nowrap;
    }
}
