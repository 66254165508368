@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/responsive-layout';

._rechnungsempfaengerContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: px2rem(0 - $defaultGap);
}
