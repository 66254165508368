@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/responsive-layout';

._container {
    display: flex;
}

._headerAreaWithSelectableElements {
    margin-right: px2rem($defaultGap);
    flex: 0.6;
}

._kfzKennzeichenAndLieferbedingung {
    display: flex;
    justify-items: stretch;

    > div {
        margin: 0 px2rem(calc($defaultGap / 2)) 1rem;
        flex: 1;
    }
    > div:first-of-type {
        margin-left: 0;
    }
    > div:last-of-type {
        margin-right: 0;
    }
}

._rechnungsEmpfaenger {
    > div {
        border: 1px solid $borderColor;
        padding: 1rem;
        flex: 1;
    }
    flex: 0.4;

    &--tippy {
        background-color: white;
        border: 1px solid $borderColor;
        border-radius: 0;
        padding: px2rem(8);
        color: $fontColor;
    }
}
