@import '../../../styles/responsive-layout';
@import '../../../styles/variables';
@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/fonts';

._textAndInputField {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
