@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/fonts';

._links {
    background-color: $footerColor;

    display: flex;
    align-items: center;
    justify-content: center;

    @include bold-font(px2rem(21), px2rem(21));
    text-transform: uppercase;
    letter-spacing: 0.02em;

    height: 2rem;

    & a {
        color: $white;
        text-decoration: none;
        padding: px2rem(3) 1rem 0 1rem;
        border-right: 3px solid $white;
    }
    & a:last-of-type {
        border-right: none;
    }
}
._content {
    display: flex;
    flex-direction: row;
    justify-items: center;
    padding: 1.5rem 0.5rem;
    background-color: $footerContentColor;
    & a {
        font-size: 21px;
        color: $linkColor;
        text-decoration: none;
    }
    & div {
        margin: 0 1rem;
    }
}
._dtc {
    background-color: $footerColor;

    display: flex;
    align-items: center;
    justify-content: center;

    @include bold-font(px2rem(21), px2rem(21));
    text-transform: uppercase;
    letter-spacing: 0.02em;

    height: 2rem;

    & span {
        color: $white;
    }
}

._dtcLogo {
    height: 1rem;
}

._cremerLogo {
    width: 50%;
    max-width: 12rem;
}

._cremerContainer {
    background-color: $white;
    display: flex;
    justify-content: flex-end;
    padding: px2rem(2) px2rem($defaultGap) px2rem(2) px2rem(2);
}

h4 {
    text-transform: uppercase;
    font-size: px2rem(20);
}
