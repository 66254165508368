@import '../../../../../styles/mixins';
@import '../../../../../styles/fonts';
@import '../../../../../styles/variables';
@import '../../../../../styles/responsive-layout';

._searchInput {
    min-width: 90%;

    @include for-size(tablet-portrait-up) {
        min-width: px2rem(290);
    }
}

._subInfo {
    @include light-font(px2rem(14), px2rem(14));
    color: $labelColor;
}
