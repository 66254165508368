@import '../../styles/colors';
@import '../../styles/mixins';

._icon {
    width: px2rem(32);
    height: px2rem(32);
    margin-right: px2rem(16);
}

._message {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: px2rem(50);
    > h3 {
        margin-bottom: 0;
    }
}
