@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/fonts';
@import '../../styles/responsive-layout';
@import '../../styles/variables';

._ortPlzContainer {
    display: flex;
    align-items: stretch;
}

._plzContainer {
    width: px2rem(90);
    margin-right: px2rem(16);
}
._ortContainer {
    flex: 1;
}

._formContainer {
    display: flex;
    flex-wrap: wrap;

    & > ._formColumn {
        min-width: 100%;
    }

    @include for-size(tablet-portrait-up) {
        margin-left: px2rem(0-$defaultGap);
        margin-right: px2rem(0-$defaultGap);

        & > ._formColumn {
            min-width: px2rem(416-$defaultGap);
            flex: 1;
            margin: 0 px2rem($defaultGap);
        }
    }
}

._vvvoContainer {
    margin-bottom: px2rem(40);

    & :not(:last-child) ._vvvoNumber input {
        border-bottom: none;
    }
    & :not(:last-child) ._iconContainer {
        border-bottom: none;
    }
}

._vvvoEntry,
._vvvoTemplate {
    display: flex;
    width: 100%;
}
._vvvoNumber {
    flex: 1;
}

._iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;

    &:hover {
        background: $lightYellow;
    }
}

._kommunikationsdatenHint {
    display: inline-flex;
    width: 100%;
    align-items: center;
}

._kommunikationsdatenBlock {
    & ._kommunikationsdatenEntry:not(:last-child) ._kommunikationsdaten input {
        border-bottom: none;
    }
    & :not(:nth-last-child(2)) ._iconContainer {
        border-bottom: none;
    }
}

._kommunikationsdatenEntry,
._kommunikationsdatenTemplate {
    display: flex;
    width: 100%;
    align-items: stretch;
}

._kommunikationsdatenBlock {
    margin-top: px2rem($defaultGap);
}

._kommunikationsart {
    width: px2rem(120);
}

._kommunikationsdaten {
    flex: 1;
}

._kommunikationsdatenError {
    font-size: px2rem(14);
    color: $errorColor;
}

._bevorzugt {
    margin-right: px2rem(10);
}
