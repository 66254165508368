@import '../../../../styles/colors';
@import '../../../../styles/mixins';
@import '../../../../styles/variables';
@import '../../../../styles/responsive-layout';

._virtualListParent {
    &--full {
        height: 100%;
    }

    &--scroll {
        overflow: auto;
        height: 175px;
    }
}

._virtualListContainer {
    width: 100%;
    position: relative;
}

._virtualItem {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
