@import 'src/styles/print';
@import 'src/styles/mixins';
@import 'src/styles/variables';

._container {
    @include hide-in-print();

    h2 {
        margin-top: px2rem($defaultGap);
    }
}

._buttonGroup {
    padding-top: 0;
}
