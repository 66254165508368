@import '../../../styles/mixins';
@import '../../../styles/responsive-layout';

._searchContainer {
    display: flex;
    margin-bottom: px2rem(32);
    max-height: px2rem(42);
    align-items: flex-start;
}

._selectContainer {
    flex-grow: 1;
    margin-right: px2rem(16);
}

._artikelButtonContainer {
    @include for-size(tablet-portrait-up) {
        display: flex;
        justify-content: stretch;
        flex-direction: column;
        width: 210px;
    }
}

._hinzufuegenText {
    @include hide-mobile();
}

._hinzufuegenPlus {
    @include hide-non-mobile(inline);
}

._neuerArtikel {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
    justify-content: space-between;
}

._neuerArtikelText {
    margin-right: px2rem(13);
    margin-left: auto;
}
