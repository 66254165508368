@import '../../../styles/responsive-layout';
@import '../../../styles/variables';
@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/fonts';

._formContainer {
    display: flex;
    flex-wrap: wrap;

    & > ._formColumn {
        min-width: 100%;
    }

    & > ._formSmallColumn {
        width: calc(50% - 1rem);
        margin-right: 1rem;
    }

    & > ._formColumn._section {
        margin-bottom: px2rem($defaultGap * 2);

        &:empty {
            margin-bottom: 0;
        }
    }

    @include for-size(desktop-up) {
        margin-left: px2rem(0-$defaultGap);
        margin-right: px2rem(0-$defaultGap);

        & > ._formColumn,
        & > ._formSmallColumn {
            min-width: px2rem(416-$defaultGap);
            flex: 1;
            margin: 0 px2rem($defaultGap * 2) 0 px2rem($defaultGap);
        }

        & > ._formColumn:last-of-type,
        & > ._formSmallColumn:last-of-type {
            margin: 0 px2rem($defaultGap * 1) 0 px2rem($defaultGap);
        }
    }
}
._terminContainer {
    display: flex;
    justify-items: stretch;

    > div {
        margin: 0 px2rem(calc($defaultGap / 2)) 1rem;
        flex: 1;
    }

    > div:first-of-type {
        margin-left: 0;
    }
    > div:last-of-type {
        margin-right: 0;
    }
    > div:first-of-type:last-of-type {
        // Container has only one Element
        margin-left: 0;
        margin-right: 0;
    }

    &--zeitraum {
        flex-direction: column;
        > div {
            margin-left: 0;
            margin-right: 0;
        }

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            align-items: end;
            > div {
                margin-left: 0;
                margin-right: 0;
                flex: 1;
            }
            > div:nth-of-type(3) {
                margin-left: px2rem($defaultGap);
            }
        }
    }
}

._selectWithButtonContainer {
    display: flex;
    align-items: center;
}

._selectEdit {
    margin-left: px2rem($defaultGap);
    height: px2rem(42);
}

._sectionHeader {
    display: flex;
    align-items: baseline;
    margin-bottom: 1rem;
    border-bottom: 1px solid $yellow;

    & img {
        opacity: 0.5;
    }
    & img:hover {
        opacity: 1;
    }
    & h3 {
        width: 100%;
    }
    &--clickable {
        cursor: pointer;
    }
}

._mandatoryFieldText {
    @include light-font(px2rem(12), px2rem(12));
    color: $labelColor;
}
