@import '../../../styles/colors';
@import '../../../styles/mixins';

._button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    width: px2rem(32);
    height: px2rem(32);
    border: none;
    padding: 0;
    cursor: pointer;
    flex-direction: column;

    &:focus {
        outline: 1px solid $focusBorderColor;
    }

    &--withLabel {
        height: auto;
        width: auto;
    }

    &--fullWidth {
        width: 100%;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.3;
    }

    &--background {
        border-left: 1px solid $borderColor;
    }

    &--burger {
        background-color: #fd0;
        z-index: 5;
        background-size: 30rem 30rem;
        height: 2.6875rem;
        width: 2.6875rem;
        padding: 1px 6px 1px 6px;
    }
}

._icon {
    &--small {
        width: px2rem(18);
        height: px2rem(18);
    }

    &--large {
        width: px2rem(24);
        height: px2rem(24);
    }
}
