@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/fonts';
@import '../../../styles/responsive-layout';
@import '../../../styles/variables';

/* Position and sizing of burger button */
.bm-burger-button {
    display: none;
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 32px;
    width: 32px;
    right: 16px !important;
    top: 16px !important;
}

/* Color/shape of close button cross */
.bm-cross {
    background: transparent;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: $menuBackgroundColor;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 0.7rem;
    font-size: 1.15rem;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.4em 0.8em 0.8em 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
    text-decoration: none;
    color: $fontColor;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
