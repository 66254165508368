@import '../../../../styles/colors';
@import '../../../../styles/mixins';
@import '../../../../styles/responsive-layout';

._artikelHeadline {
    @include stornoModifier;
}

._positionenTable {
    margin-bottom: px2rem(40);
}

._buttonContainer {
    height: 100%;
}
