@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/responsive-layout';

._auswahlContainer {
    display: flex;
    flex-direction: column;
    margin: 0 px2rem(-$defaultGap);
    justify-content: center;
    align-items: center;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
    }
}

._item {
    display: flex;
    flex-direction: column;
    margin: 0 px2rem($defaultGap);
    align-items: center;
    background-color: $teaserBoxTextBackground;
    max-width: px2rem(310);
    transition: transform 0.2s;
    border: 1px solid $white;
    &:hover {
        transform: scale(1.04);
        z-index: 1;
    }
}

._itemLink {
    background-color: transparent;
    text-decoration: none;
    margin-bottom: px2rem($defaultGap);
    color: $fontColor;
    border: none;
    cursor: pointer;
    &:focus ._item {
        border: 1px solid $focusBorderColor;
    }
}

._itemText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 70px;
    padding: 1rem;

    @include bold-font(px2rem(24), px2rem(24));
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}
._itemText--subline {
    @include bold-font(px2rem(14), px2rem(14));
}

._image {
    max-width: 100%;
}

@include ie-only-styles() {
    ._image {
        width: 318px;
        height: 318px;
    }
}
