@import 'src/styles/colors';

._buttonIcon {
    position: relative;
    padding-right: 4rem;

    &__imageContainer {
        position: absolute;
        right: 0;
        top: 0;
        width: 3rem;
        height: 100%;
        background-color: $white;
    }

    &__image {
        position: absolute;
        background-color: $white;
        width: 1.5rem;
        height: 1.5rem;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);

        > svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    &:hover {
        ._buttonIcon__imageContainer,
        ._buttonIcon__image {
            background-color: $yellow;
        }
    }
}
