@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/responsive-layout';

._navigationContainer {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
}

._navigationGroup {
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 3rem);
    margin: 0 0 0 0;
    justify-content: space-between;
    @include for-size(tablet-portrait-up) {
        justify-content: unset;
    }
}

._navigationEntry {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    background: $buttonBackground;
    cursor: pointer;
    margin-left: 0.6rem;
    margin-right: 0.6rem;
    margin-top: 0;
    margin-bottom: 1rem;

    transition: transform 0.2s;

    border: 1px solid $white;
    &:focus {
        border-color: $focusBorderColor;
    }

    &:hover {
        background-color: $buttonHoverColor;
        border: none;
        transform: scale(1.04);
        z-index: 1;
    }

    width: calc(50vw - 2.5rem);
    max-width: 14rem;

    @include for-size(tablet-portrait-up) {
        width: calc(33vw - 3rem);
    }

    @include for-size(desktop-up) {
        width: calc(25vw - 3rem);
        max-width: 13.75rem;
    }
}
._textWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: px2rem(12) px2rem(18);
    height: 4.5rem;
}

._navigationText {
    @include bold-font(1rem, px2rem(23));
    text-align: center;
    @include for-size(tablet-portrait-up) {
        @include bold-font(px2rem(23), px2rem(23));
    }
}

._navigationImage {
    max-width: 100%;
}

._group {
    display: inline-block;
}

._accountLoeschen {
    display: none;
}
