@import '../../../../styles/colors';
@import '../../../../styles/mixins';

._positionenTable {
    margin-bottom: px2rem(40);
}

._abrechnungsContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}
._kontraktMeldung {
    padding: px2rem(3) 0 px2rem(6) px2rem(9);
    font-size: px2rem(14);
    color: $errorColor;
}
