@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';

._statusIcons {
    white-space: nowrap;
    display: flex;
    justify-content: center;
}
._statusIcon {
    vertical-align: middle;
    width: px2rem(24);
    height: px2rem(24);
    margin-right: px2rem(calc($defaultGap / 2));
    :last-of-type {
        margin-right: 0;
    }
}
