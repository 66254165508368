@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/responsive-layout';

._headerContainer {
    display: flex;
    align-items: center;
}
._partnernummer {
    margin-top: px2rem($defaultGap);
}

._editIcon {
    width: px2rem(24);
    height: px2rem(24);
}

._searchField__container {
    margin-bottom: px2rem(8);
    margin-left: auto;
}
._searchField {
    border: none;
    border-bottom: 1px solid $labelColor;
    padding-bottom: 0;
    width: px2rem(100);
    transition: width 300ms ease-out;

    &:focus,
    &:not([value='']) {
        width: px2rem(300);

        &::placeholder {
            color: $lightGray;
        }
    }

    &::placeholder {
        color: white;
    }
}

:export {
    breakpoint-tablet-portrait-up: $breakpoint-tablet-portrait-up;
}
