@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/fonts';

._input {
    @include light-font(px2rem($inputFontSize), px2rem($inputFontSize + 3));
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    padding: px2rem(12) px2rem(8) px2rem(8);
    background: $inputBackgroundColor;
    width: 100%;

    &:hover {
        background: $inputHoverBackgroundColor;
    }

    &[disabled] {
        background: $inputBackgroundColorDisabled;
        border-color: $borderColorDisabled;

        &:hover {
            background: $white;
        }
    }

    @include stornoModifier;

    &--regular {
        border: 1px solid $borderColor;
        height: px2rem(42);
    }

    &--inTableCell {
        height: 100%;
        border: 1px solid transparent;
        @media print {
            border: 1px solid transparent;
        }

        &[disabled] {
            border: 1px solid transparent;
        }
    }
    &:focus {
        border: 1px solid $focusBorderColor;
        background: $focusBackgroundColor;
        outline: none;
    }

    &--error {
        border: 1px solid $errorColor;
        @media print {
            border: 1px solid $errorColor;
        }
        &:focus {
            border: 1px solid $errorColor;
        }
    }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

._error {
    font-size: px2rem(14);
    color: $errorColor;
}

._container {
    margin-bottom: px2rem(16);
    width: 100%;
}

._inputContainer {
    height: px2rem(42);
}
