@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/responsive-layout';

._dialog {
    padding: px2rem($defaultGap);
    margin: 2rem;
    background-color: $white;
    box-shadow: 0 3px 32px 0 rgba(0, 0, 0, 0.3);
    &__buttons {
        display: flex;
        flex-direction: column-reverse;

        & button {
            margin-top: px2rem(calc($defaultGap / 2));
        }

        @include for-size(tablet-landscape-up) {
            justify-content: flex-end;
            flex-direction: row;
            & button {
                margin-left: px2rem($defaultGap);
            }
        }
    }
}
