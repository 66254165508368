@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/fonts';
@import '../../../styles/responsive-layout';
@import '../../../styles/variables';

$maxFilterEntries: 6;

@mixin filter-col-list {
    @for $i from 1 through $maxFilterEntries {
        &--space-#{$i} {
            flex: $i;
        }
    }
}

._filterPanel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 3;
    gap: px2rem(16);
    justify-content: flex-start;
    margin-bottom: px2rem($defaultGap);

    @include for-size(tablet-landscape-up) {
        justify-content: flex-end;

        &--space-between {
            justify-content: space-between;
        }
    }
}

._filter {
    min-width: 100%;

    @include for-size(tablet-landscape-up) {
        min-width: px2rem(250);
        @include filter-col-list;
    }
}
