$breakpoint-tablet-portrait-up: 576;
$breakpoint-tablet-landscape-up: 769;
$breakpoint-desktop: 992;
$breakpoint-big-desktop: 1200;

@mixin for-size($size) {
    @if $size == tablet-portrait-up {
        @media (min-width: $breakpoint-tablet-portrait-up + 'px') {
            @content;
        }
    } @else if $size == tablet-landscape-up {
        @media (min-width: $breakpoint-tablet-landscape-up + 'px') {
            @content;
        }
    } @else if $size == desktop-up {
        @media (min-width: $breakpoint-desktop  + 'px') {
            @content;
        }
    } @else if $size == big-desktop-up {
        @media (min-width: $breakpoint-big-desktop + 'px') {
            @content;
        }
    }
}

@mixin hide-mobile() {
    @media (max-width: ($breakpoint-tablet-portrait-up - 1)+'px') {
        display: none;
    }
}

@mixin hide-non-mobile($displayNonMobile) {
    display: none;
    @media (max-width: ($breakpoint-tablet-portrait-up - 1)+'px') {
        display: $displayNonMobile;
    }
}
