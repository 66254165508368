@use '../../shared/ui-components/ButtonGroup/ButtonGroup.module';
@import '../../styles/colors';
@import '../../styles/responsive-layout';
@import '../../styles/mixins';
@import '../../styles/variables';

._multiButtonRowButtonGroup {
    @extend ._buttonGroup;
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    width: 100%;
    min-width: 100%;

    div {
        display: flex;
        justify-content: space-between;

        button {
            width: 32%;
        }
    }

    > button {
        margin-left: 0 !important;
        margin-top: 1vh;
    }
}

:export {
    breakpoint-tablet-portrait-up: $breakpoint-tablet-portrait-up;
}

._addIcon {
    transition: all 300ms;
    width: px2rem(16);
    height: px2rem(16);
}

._bestellungLink {
    display: flex;
    align-items: center;

    span {
        padding: px2rem(4) 0 0 0;
        margin-right: px2rem($defaultGap);
    }
}

._editIcon {
    width: px2rem(24);
    height: px2rem(24);
}
