@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/responsive-layout';
@import '../../../styles/print';

._cookieJar {
    @include hide-in-print();
    position: fixed;
    display: flex;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: $white;
    border-top: 1px solid $black;
    flex-direction: column;
    z-index: 10;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
    }
}

._cookieButtonContainer {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    @include for-size(tablet-portrait-up) {
        margin-left: auto;
        flex-direction: column;
    }

    > button {
        border-top: 1px solid $black;
        width: 50%;
        height: px2rem(60);

        &:first-of-type {
            border-right: 1px solid $black;
        }

        @include for-size(tablet-portrait-up) {
            min-width: px2rem(200);
            height: 50%;
            width: auto;
            border-top: none;
            border-left: 1px solid $black;
            &:first-of-type {
                border-bottom: 1px solid $black;
                border-right: none;
            }
        }
    }
}

._cookieTextContainer {
    padding: px2rem($defaultGap);
    flex-grow: 3;
}
