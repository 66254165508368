@import './fonts';
@import './colors';
@import './variables';
@import './mixins';
@import './print';

@font-face {
    font-family: HelveticaNeueLTPro;
    font-display: swap;
    font-weight: 400;
    font-style: normal;
    src: url('../assets/fonts/HelveticaNeueLTPro_57-Cn.woff2') format('woff2'),
        url('../assets/fonts/HelveticaNeueLTPro_57-Cn.woff') format('woff');
}

@font-face {
    font-family: HelveticaNeueLTPro;
    font-display: swap;
    font-weight: 700;
    font-style: normal;
    src: url('../assets/fonts/HelveticaNeueLTPro_77-BdCn.woff2') format('woff2'),
        url('../assets/fonts/HelveticaNeueLTPro_77-BdCn.woff') format('woff');
}

@font-face {
    font-family: HelveticaNeueLTStd-Cn;
    font-display: swap;
    font-weight: 400;
    font-style: normal;
    src: url('../assets/fonts/HelveticaNeueLTPro_57-Cn.woff2') format('woff2'),
        url('../assets/fonts/HelveticaNeueLTPro_57-Cn.woff') format('woff');
}

@font-face {
    font-family: HelveticaNeueLTStd-Cn;
    font-display: swap;
    font-weight: 700;
    font-style: normal;
    src: url('../assets/fonts/HelveticaNeueLTPro_77-BdCn.woff2') format('woff2'),
        url('../assets/fonts/HelveticaNeueLTPro_77-BdCn.woff') format('woff');
}

* {
    box-sizing: border-box;
}
body {
    -webkit-font-feature-settings: 'kern' 1, 'liga' 1, 'calt' 1, 'pnum' 1, 'tnum' 0, 'onum' 1, 'lnum' 0, 'dlig' 0;
    font-feature-settings: 'kern' 1, 'liga' 1, 'calt' 1, 'pnum' 1, 'tnum' 0, 'onum' 1, 'lnum' 0, 'dlig' 0;
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;

    font-family: $fontFamilyCondensed;
    color: $fontColor;
    background-color: $bodyBackgroundColor;

    @media print {
        background-color: $white;
    }
}

*:focus {
    outline: none;
}

h1 {
    margin-top: 0;
    margin-bottom: px2rem($defaultGap * 2);
    text-transform: uppercase;
    letter-spacing: 0.02em;
    @include bold-font(px2rem(40), px2rem(40));
}

h2 {
    margin-top: 0;
    margin-bottom: px2rem($defaultGap);
    text-transform: uppercase;
    @include bold-font(px2rem(32), px2rem(32));
}

h3 {
    margin-top: 0;
    margin-bottom: px2rem($defaultGap * 0.5);
    @include regular-font(px2rem(23), px2rem(23));
}

h4 {
    margin-top: 0;
    margin-bottom: px2rem($defaultGap * 0.5);
    @include semibold-font(px2rem(18), px2rem(18));
}

a {
    color: $linkColor;
}
