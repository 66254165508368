@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/fonts';
@import '../../styles/responsive-layout';
@import '../../styles/variables';

._kontraktFilter {
    width: 100%;
    margin-bottom: 1rem;
    @include for-size(tablet-portrait-up) {
        width: px2rem(220);
        margin-bottom: 0.1rem;
        margin-left: 1rem;
        &:first-of-type {
            margin-left: 0;
        }
    }
}

._aggregatedMengen {
    background-color: $yellow;
}
