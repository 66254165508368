@import '../../../styles/responsive-layout';
@import '../../../styles/mixins';
@import '../../../styles/fonts';

._iconInfo {
    width: px2rem(16);
    height: px2rem(16);
}

._info {
    @include light-font(px2rem(14), px2rem(14));
    color: $labelColor;
}

._textAndInputField {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}
