@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/responsive-layout';

._positionContainer {
    border-bottom: 1px solid $borderColor;
    margin: px2rem($defaultGap) 0;
    padding-bottom: px2rem(6);
    &:last-of-type {
        border-bottom: none;
        margin-bottom: px2rem(32);
    }
}
