@import '../../../styles/variables';
@import '../../../styles/mixins';

._aenderbarBis {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    margin-left: px2rem($defaultGap);

    &__icon {
        width: 1.5em;
        height: 1.5em;
    }
}
