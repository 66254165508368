@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/responsive-layout';

._warenempfaengerContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: px2rem(0 - $defaultGap);
}

._neuerWarenempfaenger {
    @include light-font(px2rem($inputFontSize), px2rem($inputFontSize));
    display: flex;
    flex-direction: row;
    align-items: center;
    position: sticky;
    margin: px2rem($defaultGap);
    border: 1px solid $selectedBorderColor;
    text-decoration: none;
    color: $fontColor;
    padding: px2rem($defaultGap);
    transition: transform 0.2s;
    cursor: pointer;
    width: calc(100% - 2rem);
    bottom: 70px;
    background-color: $white;
    z-index: 1;

    &:focus {
        border: 1px solid $focusBorderColor;
    }
    &:hover {
        transform: scale(1.04);
        z-index: 1;
    }
}

._plzOrt {
    margin-top: px2rem(0.5 * $defaultGap);
}

._newAdressLabel {
    margin-left: px2rem($defaultGap);
}
._addIcon {
    width: px2rem(24);
    height: px2rem(24);
}
._searchForm {
    display: flex;
    margin-bottom: px2rem($defaultGap);
}
