@import 'colors';

$fontFamilyCondensed: HelveticaNeueLTStd-Cn, Arial Narrow, Helvetica, Arial, sans-serif;
$fontFamilyBold: HelveticaNeueLTPro, Arial Narrow, Helvetica, Arial, sans-serif;
$inputFontSize: 17;

@mixin light-font($fontSize, $lineHeight) {
    font-family: $fontFamilyCondensed;
    font-weight: 300;
    font-style: normal;
    font-size: $fontSize;
    line-height: $lineHeight;
    color: $fontColor;
}

@mixin regular-font($fontSize, $lineHeight) {
    font-family: $fontFamilyCondensed;
    font-weight: 400;
    font-style: normal;
    font-size: $fontSize;
    line-height: $lineHeight;
    color: $fontColor;
}

@mixin semibold-font($fontSize, $lineHeight) {
    font-family: $fontFamilyBold;
    font-weight: 600;
    font-style: normal;
    font-size: $fontSize;
    line-height: $lineHeight;
    color: $fontColor;
}

@mixin bold-font($fontSize, $lineHeight) {
    font-family: $fontFamilyBold;
    font-weight: 700;
    font-style: normal;
    font-size: $fontSize;
    line-height: $lineHeight;
    color: $fontColor;
}
