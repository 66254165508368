@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/variables';

._buttonGroup {
    display: flex;
    justify-content: flex-end;
    margin-top: px2rem($defaultGap);
    z-index: 2;
    position: sticky;
    bottom: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%);
    padding: px2rem(24) 0 px2rem(16);

    > button {
        margin-left: px2rem($defaultGap);
    }

    &--leftAligned {
        justify-content: flex-start;
    }

    &--rightAligned {
        justify-content: flex-end;
    }

    &--centerAligned {
        justify-content: center;
    }

    &--mobile {
        flex-direction: column;
        > button {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: px2rem(8);
        }
    }

    &--withinCell {
        margin-top: 0;
        padding: 0;
        position: initial;
        background: initial;
    }
}
