@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/fonts';
@import '../../../styles/responsive-layout';
@import '../../../styles/variables';

ul {
    margin-block-start: 0;
    margin-block-end: 0;
}

._accordion {
    margin: 0rem 0rem 1rem 0rem;
    @include for-size(tablet-portrait-up) {
        margin: 2rem 1rem 2rem 1rem;
    }
}

._accordion ._accordionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff8d3;
    border: 1px solid #e0b300;
    font-family: HelveticaNeueLTStd-Cn, Arial Narrow, Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1.0625rem;
    line-height: 2.9rem;
    color: #404040;
    height: 2.625rem;
    cursor: pointer;
    overflow: hidden;
    @include for-size(tablet-portrait-up) {
        display: flex;
        justify-content: space-between;
        background-color: #fff8d3;
        border: 1px solid #e0b300;
        font-family: HelveticaNeueLTStd-Cn, Arial Narrow, Helvetica, Arial, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 1.0625rem;
        line-height: 2.9rem;
        color: #404040;
        height: 2.625rem;
        cursor: pointer;
    }
}

._rationsserviceText {
    display: none;
    @include for-size(tablet-portrait-up) {
        display: inline;
    }
}

._accordionName {
    display: flex;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0.3rem 0rem 0rem 0.3rem;
    @include for-size(tablet-portrait-up) {
        margin: 0.3rem 0rem 0rem 0.7rem;
    }
}

._accordionDate {
    text-align: right;
    margin-left: auto;
}

._longDate {
    display: none;
    @include for-size(tablet-portrait-up) {
        display: inline;
    }
}

._shortDate {
    display: inline;
    @include for-size(tablet-portrait-up) {
        display: none;
    }
}

._lastDate {
    //display: none;
}

._accordionExtendButton {
    margin: 0rem 0.7rem 0rem 0.7rem;
    svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 1.3rem;
    }
}

._arrowUpIcon {
    transform: rotate(180deg);
}
