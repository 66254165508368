// stylesheet colors
$white: #fff;
$red: #e3000b;
$yellow: #fd0;
$black: #404040;

$lightestYellow: #fffef6;
$lightYellow: #fff8d3;
$lightGray: #c9c9c9;
$gray: #6e6e6e;

$lightBlue: #e7f2fa;
$lightGreen: #dceee0;
$farmYellow: #ffed76;
$darkYellow: #e0b300;
$green: #64b32c;
$orange: #ff9600;

// shell
$bodyBackgroundColor: #f3f3f3;
$mainBackgroundColor: $white;
$fontColor: $black;
$headerColor: $yellow;
$menuBackgroundColor: $yellow;
$fontColorDisabled: $lightGray;
$linkColor: #6e6e6e;
$footerColor: $gray;
$footerContentColor: $lightYellow;

// error handling
$errorColor: rgba(248, 0, 0, 0.7);
$errorBackgroundColor: rgba(248, 0, 0, 0.3);

$confirmedColor: $lightGreen;

// components
$buttonBackground: $yellow;
$buttonHoverColor: $darkYellow;
$buttonSecondaryBackground: $lightYellow;
$buttonDisabledBackground: $white;
$buttonDisabledColor: $lightGray;
$buttonDisabledBorderColor: $lightGray;

$borderColor: $darkYellow;
$borderColorDisabled: $lightGray;

$focusBorderColor: $black;
$focusBackgroundColor: $lightYellow;

$inputBackgroundColor: lighten($buttonBackground, 48%);
$inputBackgroundColorDisabled: $white;
$inputHoverBackgroundColor: $lightYellow;

$optionHoverBackgroundColor: $darkYellow;

$labelColor: $gray;

$selectedColor: $darkYellow;
$selectedBorderColor: $yellow;
$teaserBoxTextBackground: $yellow;
$inputCellBackground: $lightestYellow;

$callToActionBackground: $lightYellow;

// tables
$tableHeaderColor: $lightYellow;
$tableRowColor: $white;
