@import '../../../styles/colors';
@import '../../../styles/mixins';

._textarea {
    resize: vertical;
    height: 5rem;

    &--inTableCell {
        vertical-align: top;
        border: 1px solid transparent;
        height: 100%;
    }

    &--non-resizeable {
        resize: none;
    }

    @media print {
        display: none;
    }
}
._print {
    display: none;
    white-space: pre-wrap;
    padding: px2rem(12) px2rem(8) px2rem(8);
    background: $inputBackgroundColor;
    border: 1px solid $borderColor;

    &--inTableCell {
        height: 100%;
        border: none;
    }

    @media print {
        display: block;
    }
}
