@import '../../../../../styles/mixins';
@import '../../../../../styles/colors';

._kesselHeaderWrapper {
    display: flex;
    align-content: space-between;
    flex-direction: column;
}

._tableCell {
    width: px2rem(240);
}

._kesselHeader {
    display: flex;
    justify-content: space-evenly;

    div {
        flex: 1;
    }
}

._kesselWrapper {
    display: flex;
    height: 100%;

    :nth-child(n) {
        input {
            font-size: px2rem(14);
            text-align: center;
            padding: 0;
            height: 100%;
            border-style: hidden;
            border-right: px2rem(0.5) $borderColor solid;
            @media print {
                border-right: px2rem(0.5) $borderColor solid;
            }
        }
    }

    :last-child {
        input {
            border: none;
        }
    }
}

._kessel {
    display: flex;
    flex-direction: column;
    align-items: center;
}
