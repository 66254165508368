@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/fonts';
@import '../../styles/responsive-layout';
@import '../../styles/variables';

._headerContainer {
    display: flex;
    align-items: center;
}

._subInfo {
    @include light-font(px2rem(14), px2rem(14));
    color: $labelColor;

    @include stornoModifier;
}

._emptySammelbestellung {
    background-color: #fff8d3;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
}

._editIcon {
    width: px2rem(24);
    height: px2rem(24);
}

._mobileBestellnummer {
    margin-top: px2rem($defaultGap);
}

._panelAboveTable {
    display: flex;
    margin-bottom: px2rem($defaultGap);
}

._sammelbestellungPanelTop {
    flex: 1;
}

._sammelbestellungPanelBottom {
    margin-top: px2rem($defaultGap);
}

._filterPanel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 3;
    justify-content: flex-start;
    align-items: flex-end;

    @include for-size(tablet-portrait-up) {
        justify-content: flex-end;
    }
}

._filter {
    width: 100%;
    margin-bottom: 1rem;
    @include for-size(tablet-portrait-up) {
        width: px2rem(250);
        margin-bottom: 1rem;
        margin-left: 1rem;
        &:first-of-type {
            margin-left: 0;
        }
    }
    @include for-size(big-desktop-up) {
        margin-bottom: 0;
    }
}

:export {
    breakpoint-tablet-portrait-up: $breakpoint-tablet-portrait-up;
    breakpoint-desktop: $breakpoint-desktop;
    big-desktop-up: $breakpoint-big-desktop;
}
