@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/responsive-layout';
@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/print';

._loading {
    display: flex;
    width: 100vw;
    justify-content: center;
    position: absolute;
    z-index: 99;
}

._container {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 0 calc(100%) 0;
    grid-template-rows: auto auto auto;
    -ms-grid-columns: 0 calc(100%) 0;
    -ms-grid-rows: auto auto auto;
    min-height: 100vh;

    @media print {
        display: block;
    }

    @include for-size(tablet-portrait-up) {
        -ms-grid-columns: px2rem($defaultGap) calc(100% - #{px2rem($defaultGap * 2)}) px2rem($defaultGap);
        grid-template-columns: px2rem($defaultGap) calc(100% - #{px2rem($defaultGap * 2)}) px2rem($defaultGap);
    }

    @include for-size(desktop-up) {
        -ms-grid-columns: 1fr px2rem($breakpoint-desktop) 1fr;
        grid-template-columns: 1fr px2rem($breakpoint-desktop) 1fr;

        &__headercontent {
            width: px2rem($breakpoint-desktop);
        }
    }

    @include for-size(big-desktop-up) {
        -ms-grid-columns: 1fr px2rem($breakpoint-big-desktop) 1fr;
        grid-template-columns: 1fr px2rem($breakpoint-big-desktop) 1fr;

        &__headercontent {
            width: px2rem($breakpoint-big-desktop);
        }
    }
}

._headerBackground {
    @include hide-in-print();
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    -ms-grid-row: 1;
    grid-column: 1/4;
    grid-row: 1;
    background: $headerColor;
    height: px2rem(43);
    margin-top: 0;
    margin-bottom: px2rem(32);
    z-index: 3;
    position: sticky;
    top: 0;

    @include for-size(tablet-portrait-up) {
        margin-top: px2rem(16);
    }
}

._header {
    @include hide-in-print();
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
    grid-column: 2;
    grid-row: 1;
    height: px2rem(43);
    margin-top: 0;
    margin-bottom: px2rem(32);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
    padding-right: px2rem(12);

    @include for-size(tablet-portrait-up) {
        padding-right: 0;
        margin-top: px2rem(16);
    }
}

._headerContent {
    display: flex;
    align-items: flex-start;
    width: calc(100% - 20px);
    grid-row: 1;
    grid-column: 2/3;
    z-index: 3;
    margin: 1rem auto 0;
    @include for-size(tablet-portrait-up) {
        margin-top: 1.5rem;
    }
}

._profileImage {
    width: px2rem(24);
    height: px2rem(24);
}

._logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    color: $fontColor;
    margin-left: px2rem($defaultGap);
}

._deukaContainer {
    background-color: $headerColor;
    padding: px2rem(2) px2rem(8);
    margin-left: px2rem($defaultGap);
    @include for-size(tablet-portrait-up) {
        margin-left: 0;
    }
}

._deukalogo {
    height: px2rem(38);
    width: px2rem(96);
    @include for-size(tablet-portrait-up) {
        height: px2rem(50);
        width: px2rem(126);
    }
}

._deukaCompanionlogo {
    height: px2rem(30);
    margin: px2rem(4) px2rem(0) 0 px2rem(14);
    @include for-size(tablet-portrait-up) {
        margin: px2rem(4) px2rem(0) 0 px2rem(14);
    }
}

._clublogo {
    height: px2rem(30);
    margin: 0 px2rem(16) 0;
    @include for-size(tablet-portrait-up) {
        margin: px2rem(4) px2rem(16) 0;
    }
}

._nordkraftlogo {
    height: px2rem(30);
    margin-top: 0;
    @include for-size(tablet-portrait-up) {
        margin-top: px2rem(4);
    }
}

._main {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    grid-column: 2;
    grid-row: 2;
    padding: px2rem($defaultGap);
    @include for-size(tablet-portrait-up) {
        padding: px2rem($defaultGap * 1.5);
    }
}

._footer {
    @include hide-in-print();
    -ms-grid-column: 2;
    -ms-grid-row: 3;
    grid-column: 2;
    grid-row: 3;
}

._mainBackground {
    @include hide-in-print();
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    grid-column: 2;
    border-radius: 5px;
    grid-row: 2;
    background-color: $mainBackgroundColor;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    // forces ie11 to stretch to full height
    min-height: calc(100vh - 8.5rem);
}

._burgerIcon {
    width: px2rem(32);
    height: px2rem(32);
}

._menuItemGroup {
    margin-bottom: px2rem($defaultGap);
}

._menuItem {
    display: block;
    cursor: pointer;
    color: $fontColor;
    text-decoration: none;
    padding: 0.5rem 0 0.2rem 0.5rem;
    margin-left: -0.5rem;
    &--active,
    &:hover {
        color: $black;
        background-color: $lightYellow;
    }
}

._accountLoeschen {
    display: block;
    cursor: pointer;
    color: $fontColor;
    text-decoration: none;
    padding: 0.5rem 0 0.2rem 0.5rem;
    margin-left: -0.5rem;
    margin-top: -1rem;
    &--active,
    &:hover {
        color: $black;
        background-color: $lightYellow;
    }
}

._account {
    display: block;
    color: $fontColor;
    text-decoration: none;
    padding: 0.5rem 0 0.2rem 0.5rem;
    margin-left: -0.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

._menuLabel {
    @include light-font(px2rem(14), px2rem(14));
    color: $labelColor;
}

._divider {
    border: none;
    border-top: 1px solid #404040;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
}

._accountContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

._accountIcon {
    width: 2rem;
    margin-right: 1rem;
}

._stickyFix {
    @include hide-in-print();
    position: sticky;
    background: white;
    background: linear-gradient(180deg, white 0%, white 75%, rgba(255, 255, 255, 0) 100%);
    top: 2.7rem;
    height: 0.7rem;
    grid-column: 2;
    grid-row: 2;
    z-index: 1;
    @include for-size(tablet-portrait-up) {
        height: px2rem(21);
    }
}
