@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/responsive-layout';

._emptyArticlesMessage {
    background-color: $callToActionBackground;
    padding: px2rem(3 * $defaultGap) px2rem($defaultGap);
    margin-bottom: px2rem($defaultGap);
}

._modifikationsContainer {
    padding: px2rem($defaultGap);
}

:export {
    breakpoint-tablet-portrait-up: $breakpoint-tablet-portrait-up;
}
