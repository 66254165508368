@use '../../shared/ui-components/ButtonGroup/ButtonGroup.module';
@import '../../styles/colors';
@import '../../styles/responsive-layout';
@import '../../styles/mixins';
@import '../../styles/fonts';
@import '../../styles/variables';

._multiButtonRowButtonGroup {
    @extend ._buttonGroup;
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;

    div {
        display: flex;
        justify-content: space-between;
        button {
            width: 47.5%;
        }
    }

    > button {
        margin-left: 0 !important;
        margin-top: 1vh;
    }
}

._bestellungLink {
    display: flex;
    align-items: center;
    span {
        padding: px2rem(4) 0 0 0;
        margin-right: px2rem($defaultGap);
    }
}

._editIcon {
    width: px2rem(24);
    height: px2rem(24);
}

._kommentar {
    @include light-font(px2rem(12), px2rem(12));
    color: $labelColor;
}

._statusIcon {
    vertical-align: middle;
    width: px2rem(24);
    height: px2rem(24);
}
