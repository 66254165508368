@import '../../../styles/colors';
@import '../../../styles/mixins';

._ahsPrompt {
    display: flex;
    align-items: center;
    background-color: $buttonBackground;
    height: 7rem;
}
._ahsButton {
    width: 100%;
    background-color: $buttonBackground;
    border: none;
}
._ahsDismissButton {
    width: 32px;
}

._emphasis {
    text-decoration: underline;
}
