@import '../../styles/colors';
@import '../../styles/responsive-layout';
@import '../../styles/mixins';

._statusIcon {
    vertical-align: middle;
    width: px2rem(24);
    height: px2rem(24);
}

:export {
    breakpoint-tablet-portrait-up: $breakpoint-tablet-portrait-up;
}
