@import '../../../styles/colors';
@import '../../../styles/mixins';

._error {
    font-size: px2rem(14);
    color: $errorColor;
}

._container {
    margin-bottom: px2rem(16);
    width: 100%;
    &--inTableCell {
        height: 100%;
        margin-bottom: 0;
    }
}

._inputContainer {
    height: px2rem(42);
    &--inTableCell {
        height: 100%;
        margin-bottom: 0;
    }
}
