@import '../../../styles/colors';
@import '../../../styles/mixins';

._sortHeader {
    display: inline-block;
    padding: px2rem(4) px2rem(8) px2rem(2);
    &--active {
        background-color: $buttonBackground;
    }
}

._row {
    border-bottom: 1px solid $borderColor;
    cursor: pointer;
}
