@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/fonts';
@import '../../../styles/variables';

._checkbox {
    display: flex;

    &--left {
        justify-content: start;
    }

    &--center {
        justify-content: center;
    }

    &--right {
        justify-content: end;
    }

    &--multiline {
        margin-bottom: px2rem(calc($defaultGap / 2));
    }
}

._checkboxLabel {
    display: inline-block;
    position: relative;
    margin-left: px2rem($defaultGap);
    color: $fontColor;
    cursor: pointer;
    @include regular-font(px2rem($inputFontSize), 1.5);

    &--multiline {
        @include regular-font(px2rem($inputFontSize), 1.2);
    }
}

._checkboxLabel--table::after {
    left: px2rem(1);
    top: px2rem(-4);
}

.android _checkboxLabel::after {
    top: 1px;
}

._checkboxInput[type='checkbox'] {
    position: relative;
    -webkit-appearance: none;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    border: 1px solid #e0b300;
    background-color: $white;
    cursor: pointer;

    &:hover:not([disabled]) {
        background-color: $lightYellow;
    }

    &:disabled {
        border-color: $lightGray;
    }

    &:checked:after {
        position: absolute;
        content: 'L';
        font-family: Arial, sans-serif;
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 0;
        top: 45%;
        left: 47.5%;
        -webkit-transform: translateX(-50%) scaleX(-0.75) rotate(-32.5deg);
        transform: translateX(-50%) scaleX(-0.75) rotate(-32.5deg);
    }
}
