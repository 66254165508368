@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/responsive-layout';

._subHeadline {
    margin-top: 0;

    @include for-size(tablet-landscape-up) {
        margin-top: px2rem(62);
    }
}

._suche {
    display: flex;
    margin-bottom: px2rem($defaultGap);
    & button {
        margin-left: px2rem($defaultGap);
    }
}

._markenZuordnungContainer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

._alleMarken {
    width: calc(50% - #{px2rem(2 * $defaultGap)});
}

._listContainer {
    border: 1px solid $borderColor;
    padding: px2rem(2);
    overflow-y: auto;

    &--fixedHeightMarken {
        height: px2rem(194);
    }
    &--fixedHeightArtikel {
        height: px2rem(240);
    }
    &--fullHeight {
        height: 100%;
        max-height: px2rem(299);
    }
    & label {
        width: 100%;
        white-space: nowrap;
        @include for-size(tablet-portrait-up) {
            width: initial;
            white-space: initial;
        }
    }

    @include for-size(tablet-landscape-up) {
        padding: px2rem($defaultGap);
        &--fullHeight {
            padding: px2rem(6) 1rem 1rem 1rem;
        }
    }
}

._zuordnungActions {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    padding: px2rem(calc($defaultGap / 2));
    align-self: center;
    width: px2rem(3 * $defaultGap);
    & > button {
        margin-bottom: px2rem($defaultGap);
    }

    @include for-size(tablet-landscape-up) {
        padding: px2rem($defaultGap);
        width: px2rem(4 * $defaultGap);
    }
}

._ausgewaehlteMarken {
    width: calc(50% - #{px2rem(2 * $defaultGap)});
    display: flex;
    flex-direction: column;
}

._selectedEntry {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    @include regular-font(px2rem($inputFontSize), 1.2);
}

:export {
    breakpoint-tablet-portrait-up: $breakpoint-tablet-portrait-up;
}
