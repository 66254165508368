@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/responsive-layout';

._notificationLink {
    color: $black;
}

._artikelnr {
    @include hide-mobile();
}

._artikelBezeichnung {
    @include hide-mobile();
}

._artikelNrBezeichnung {
    @include hide-non-mobile(table-cell);
}

._bestellinformationen {
    background-color: $white;
    position: sticky;
    bottom: px2rem(60);
    padding-top: px2rem($defaultGap);
    z-index: 3;
}
