@mixin hide-in-print() {
    @media print {
        display: none;
    }
}

@media print {
    @page {
        size: landscape;
    }
}
