@import './colors';

@function px2rem($size) {
    $remSize: calc($size / 16);
    @return #{$remSize}rem;
}

@mixin stornoModifier {
    &--storniert {
        color: $red;
        text-decoration: line-through;

        input,
        textarea {
            color: red;
            text-decoration: line-through;
        }
    }
}

@mixin ie-only-styles() {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}
