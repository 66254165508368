@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/responsive-layout';

@mixin hideLeftAndRightBorders() {
    &:nth-of-type(3) {
        border-left: none;
    }

    &:first-child {
        border-left: none;
    }

    &:last-child {
        border-right: none;
    }
}

._tableContainer {
    overflow: auto;
    // TODO: folgende Zeile konfigurierbar:
    height: calc(100vh - 155px);
    margin-bottom: px2rem($defaultGap * 2);
    @include for-size(tablet-portrait-up) {
        overflow: inherit;
        height: auto;
    }

    &--scrolling {
        overflow-x: auto;
        height: auto;

        @include for-size(big-desktop-up) {
            overflow: inherit;
            height: auto;
        }
    }

    @media print {
        break-inside: auto;
    }
}

._overviewContainer {
    overflow: auto;
    height: auto;
}

._table {
    height: 1px;
    border-collapse: collapse;
    width: 100%;

    &--spacing {
        margin-bottom: px2rem($defaultGap * 2);
    }

    tr {
        height: 100%;
    }
}

._tableHeaderRow {
    th {
        background-color: $tableHeaderColor;
        padding: px2rem(14) px2rem(12) px2rem(10);
        border: 1px solid $borderColor;
        z-index: 2;
        @media print {
            border: 1px solid $borderColor;
        }
        @include hideLeftAndRightBorders();
        top: -1px;
        @include for-size(tablet-portrait-up) {
            top: px2rem(62);
        }
        @include for-size(big-desktop-up) {
            position: sticky;
        }
    }

    &--nowrap th {
        white-space: nowrap;
    }

    &--notSticky th {
        position: initial;
    }
}

._overviewHeaderRow th {
    background-color: $tableHeaderColor;
    padding: px2rem(14) px2rem(12) px2rem(10);
    @media print {
        border: 1px solid $borderColor;
    }
    border: 1px solid $borderColor;
    z-index: 2;
    @include hideLeftAndRightBorders();
    white-space: nowrap;
    @include for-size(tablet-portrait-up) {
        top: px2rem(62);
    }
}

._tableSubheadRow ._tableCell {
    background-color: $tableHeaderColor;
}

._tableCell {
    padding: px2rem(7) px2rem(6) px2rem(5);
    background-color: $tableRowColor;
    border: 1px solid $borderColor;

    @include for-size(desktop-up) {
        padding: px2rem(14) px2rem(12) px2rem(10);
    }

    @media print {
        border: 1px solid $borderColor;
    }

    @include hideLeftAndRightBorders();

    &--text {
        text-align: left;
    }

    &--text-centered {
        text-align: center;
    }

    &--button {
        height: 100%;
        padding: 0;
        width: px2rem(20);
        text-align: center;
    }

    &--checkbox {
        width: px2rem(20);
        padding: 0;
        text-align: center;
    }

    &--number {
        text-align: right;

        & input {
            text-align: right;
        }
    }

    @include stornoModifier;

    &--input {
        padding: 0;
        width: 90px;
        background-color: $inputCellBackground;
    }

    &--wideInput {
        padding: 0;
    }

    &--select {
        padding: 0;
        min-width: px2rem(145);
        background-color: $inputCellBackground;
    }

    &--select-with-error {
        padding: 0;
        width: px2rem(230);
        background-color: $inputCellBackground;
    }

    &--rowHeader {
        text-align: right;
        border-right: 1px solid white;
    }

    &--master {
        border-bottom: 1px solid $white;
    }

    &--detail {
        border-top: 1px solid $white;
    }

    &--hidden {
        display: none;
    }

    &--empty {
        background: $headerColor;
    }
}

._tableSortElement {
    display: inline-block;
    font-size: 18px;
    width: px2rem(16);
    height: px2rem(20);
    padding-left: px2rem(8);
    vertical-align: middle;
}

._actionColumn {
    width: px2rem(48);
    padding: 0;
}

._datentabelle {
    border: none;
    border-collapse: collapse;
    line-height: 1.5rem;
    td {
        padding: 0 10px 0 0;
    }
}
