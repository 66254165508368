@import '../../../styles/colors';
@import '../../../styles/mixins';

._label {
    margin: 0 0 px2rem(2);
}
._text {
    padding: px2rem(13) px2rem(8) px2rem(8);
    background: $inputBackgroundColorDisabled;
    border: 1px solid $borderColorDisabled;
    margin-bottom: px2rem(16);
    &--numeric {
        text-align: right;
    }

    &--compressed {
        gap: px2rem(16);
        padding: px2rem(4) px2rem(4) px2rem(4);
        display: flex;
        justify-content: space-between;

        > span {
            text-align: right;
        }
    }

    @include stornoModifier;
}
._errorMessage {
    color: $errorColor;
    font-size: px2rem(14);
    margin-top: px2rem(-16);
}
