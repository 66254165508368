@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/responsive-layout';

._selectionIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: px2rem($defaultGap);
    height: px2rem($defaultGap);
    border-radius: 50%;
    border: 1px solid $borderColor;
    margin-right: 1.25rem;
    margin-left: 0.25rem;
}
._innerSelectionIcon {
    width: px2rem($defaultGap - 2);
    height: px2rem($defaultGap - 2);
    border-radius: 50%;
    border: 2px solid $white;
    background-color: $white;
}

._card {
    @include light-font(px2rem($inputFontSize), px2rem($inputFontSize));
    margin: px2rem($defaultGap);
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: $fontColor;
    padding: px2rem($defaultGap);
    border: 1px solid $selectedBorderColor;
    width: calc(100% - #{px2rem($defaultGap * 2)});
    transition: transform 0.2s;
    cursor: pointer;
    @include for-size(tablet-portrait-up) {
        width: calc(50% - #{px2rem($defaultGap * 2)});
    }
    &:focus {
        border: 1px solid $focusBorderColor;
    }
    &:hover {
        transform: scale(1.04);
        z-index: 1;
    }

    &--selected {
        background-color: $inputBackgroundColor;
        & ._innerSelectionIcon {
            background-color: $borderColor;
        }
    }
}
