@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/fonts';

._select {
    background-color: $inputBackgroundColor;
    font-size: px2rem($inputFontSize);
    margin-bottom: px2rem(32);

    &--inTableCell {
        margin-bottom: 0;
        height: 100%;
    }
}

._mobileSelect {
    &--error {
        border: 1px solid $errorColor;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: $fontColor;
    background: $inputBackgroundColor;
    @include light-font(px2rem($inputFontSize), px2rem($inputFontSize + 3));
    width: 100%;
    height: px2rem(42);
    border: 1px solid $borderColor;
    border-radius: 0;
    padding: px2rem(10) px2rem(8);
    cursor: pointer;
    margin-bottom: px2rem(32);

    &--inTableCell {
        margin-bottom: 0;
        border: none;
    }
}

._selectContainer {
    position: relative;
    overflow: hidden;
    &::after {
        content: '\25BC';
        position: absolute;
        background: $inputBackgroundColor;
        top: 1px;
        right: 1px;
        padding: px2rem(9);
        pointer-events: none;
    }
}

// the :export directive is the magic sauce for webpack
:export {
    hoverBackgroundColor: $optionHoverBackgroundColor;
    selectedOptionBackgroundColor: $lightYellow;
    textColor: $fontColor;
    storniertColor: $red;
    borderColor: $borderColor;
    borderColorDisabled: $borderColorDisabled;
    focusBorderColor: $focusBorderColor;
    focusBackgroundColor: $focusBackgroundColor;
    inputBackgroundColor: $inputBackgroundColor;
    inputBackgroundColorDisabled: $inputBackgroundColorDisabled;
    errorColor: $errorColor;
}
