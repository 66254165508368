@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/fonts';

._frame {
    border: none;
    min-width: 100%;
    width: 100%;
    height: 100%;
}

._searchbar {
    display: flex;
    margin-bottom: 1rem;
}

.errorMessage {
    color: red;
}

._zipCodeInputField {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 0.65rem;
    padding-right: 0.65rem;
    padding-bottom: 0.55rem;
    padding-left: 0.65rem;
    font-size: calc(1.25rem + 0.1875 * (100vw - 25rem) / 65);
    color: #404040;
    border: solid 1px #e0b300;
    margin-right: 0.5rem;
    background: #fffef6;
    -webkit-appearance: none;
}

._searchZipCodeButton {
    padding: 0.65rem 1.3rem 0.55rem;
    color: #404040;
    border: solid 1px #e0b300;
    background: #fd0;
    text-align: center;
    font-family: HelveticaNeueLTStd-Cn, Arial Narrow, Helvetica, Arial, sans-serif;
    font-size: calc(2.75rem + 0.1875 * (100vw - 25rem) / 65);
    cursor: pointer;
    position: relative;
}
