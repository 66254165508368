@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/fonts';

._search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include light-font(px2rem($inputFontSize), px2rem($inputFontSize + 3));
    appearance: none;
    border: 1px solid $borderColor;
    height: px2rem(42);
    padding: px2rem(12) px2rem(8) px2rem(8);
    background: $inputBackgroundColor;

    &:hover {
        background: $inputHoverBackgroundColor;
    }

    &[disabled] {
        background: $inputBackgroundColorDisabled;
        border-color: $borderColorDisabled;

        &:hover {
            background: $white;
        }
    }
}

._input {
    border: transparent;
    background: transparent;
    width: 100%;
}
